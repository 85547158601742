import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from './HomeIcon.png';
import Subsicon from './Subsicon.png';

// import LiveIcon from './LiveIcon.png';
// import ResourcesIcon from './ResourcesIcon.png';
// import SubscribeIcon from './SubscribeIcon.png';
// import ProfileIcon from './ProfileIcon.png';
import './Drawer.css';
import { Row, Col} from 'react-bootstrap';
import {BrowserRouter as Router, Link} from 'react-router-dom';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Drawers() {
  
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
     
     
      <Drawer
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
         
        </div>
        
        <List>
          
            <ListItem>
              
                <Link to='/Dashboard'>
              <ListItemIcon className='homeicondrawerbutton'>
                <img src={HomeIcon} alt="i" className='homeicondrawerbuttonstyling' />
              </ListItemIcon>
              <ListItemText className='homedrawerbutton'>
                <p className='homedrawerbuttontext' >Dashboard</p>
                </ListItemText>
                </Link>
               

                
                <Link to='/Userdetails'>
              <ListItemIcon className='liveicondrawerbutton'>
                <img src={Subsicon} alt="i"   />
              </ListItemIcon>
              <ListItemText className='livedrawerbutton'>
                <p className='livedrawerbuttontext'>Subscription Manager</p>
                </ListItemText>
                </Link>
                

                
                {/* <Link to='/Resources'>
              <ListItemIcon className='resourcesicondrawerbutton'>
                <img src={HomeIcon} alt="i"  />
              </ListItemIcon>
              <ListItemText className='resourcesdrawerbutton'>
                <p className='resourcesdrawerbuttontext'>Update Live Classes</p>
                </ListItemText>
                </Link>
                

                
                <Link to='/Profile'>
              <ListItemIcon className='subscribeicondrawerbutton'>
                <img src={HomeIcon} alt="i"  />
              </ListItemIcon>
              <ListItemText className='subscribedrawerbutton'>
                <p className='subscribedrawerbuttontext'>Course Updation</p>
                </ListItemText>
                </Link> */}
                

                
                 
               
                

                


            </ListItem>
          
        </List>
          
        
      </Drawer>
      
    </div>
  );
}