import React, {Component} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import './Liveclassdashboard.css';
 
import Select from 'react-select';
// import './Liveclassdashboard.css';
import Cookies from 'js-cookie';
import {Row, Col} from 'react-bootstrap';
import DatetimeRangePicker from 'react-datetime-range-picker';
 
import {BrowserRouter as Router, Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
 
import { InputGroup,FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';
  // import Descriptionpopup from "./Descriptionpopup.js";
  import 'react-tabs/style/react-tabs.css';
  import TextField from '@material-ui/core/TextField';
  import { makeStyles } from '@material-ui/core/styles';
export default class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 10,
            classCard:'',
            currentPage: 0
        };
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }
    handleChangecard(e,i) {
        console.log(`updated:`,e.target.value);    
     const upcoming=[...this.state.upcoming]
     upcoming[i].classCard=e.target.value
        const value = e.target.value;
        console.log(value);
        this.setState(
    {
      classCard: value,upcoming
    }
   
        )
        console.log(value);
        Cookies.set('url',value);
      };
    receivedData() {
        var data = {
            "beginDateTime": "2021-04-15T12:00:00.000Z",
                 "endDateTime": "2021-05-15T13:00:00.000Z",
                          "instructorName":"",
                          "limit":10000
         }
         axios({
            method: 'POST',
            url: 'http://13.235.115.31:3000/admin/dashboard/classes/filterClasses',
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
          
    }
        )
        .then(
            async  (posRes) => {
          console.log(posRes)
          this.setState({classes: posRes.data.classes,upcoming:posRes.data.classes.upcoming,past:posRes.data.classes.past})
          const data =  posRes.data.classes.upcoming;
         const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
          const postData = slice.map(el => <React.Fragment>
              {/* <p>{pd.chapterName}</p> */}
              <div className='classesinfo'>
              <Accordion 
allowMultipleExpanded={true}
allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
      <Row>
      <Col className='facultyheadingblock'>
        <p className='facultyheadingblocktext'>Instructor Name: {el.instructorName}</p>
      </Col>
      <Col className='startheadingblock'>
        <p className='facultyheadingblocktext'>Start Time: {moment.utc(moment(el.startTime)).format("MMMM Do, YYYY")}</p>
      </Col>

      <Col className='endheadingblock'>
        <p className='facultyheadingblocktext'>End Time: {moment.utc(moment(el.endTime)).format("MMMM Do, YYYY")}</p>
      </Col>
      </Row>
      <Row className='descriptiveheadingblocktop'>
     
        <p className='facultyheadingblocktexts'>{el.classDescription} +</p>
      
      </Row>
      </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel> 
      <Row>
      <Col className='chapterheadingblock'>
        <p className='facultyheadingblocktext'>Class Card: {el.classCard}</p>
      </Col>
      <Col className='titleheadingblock'>
        <p className='facultyheadingblocktext'>Class Title: {el.classTitle}</p>
      </Col>
      
      
</Row>
      

      <Row>
      <Col className='descriptiveheadingblock'>
        <p className='facultyheadingblocktext'>Class Description: {el.classDescription}</p>
      </Col>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>URL: {el.url}</p>
      </Col>
      

      </Row>
      </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
       
     
               
       
           
            
      </div>
          </React.Fragment>)

          this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
             
              postData
          })


 
         },
        async  (errRes) => {
          alert('Document not updated.Please re-verify the data.')
          console.log(errRes.response.data);
           
       
        }
        );
       
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };
    componentDidMount() {
        this.receivedData()
    }
     
    render() {
        return (
            <div>
                
                {this.state.postData}
                <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
            </div>

        )
    }
}
