import React, { Component } from 'react'
import { MDBInput } from "mdbreact";
import './Userdetails.css';
import './Liveclassdashboard.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import {Row, Col,InputGroup,FormControl} from 'react-bootstrap';
import {BrowserRouter as Router, Link, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import Email from 'react-email-autocomplete';
import axios from 'axios';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NumericInput from 'react-numeric-input';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
 
import 'react-accessible-accordion/dist/fancy-example.css';
 
import 'react-tabs/style/react-tabs.css';
export class Userdetails extends Component {
  constructor(props) {
    
    super(props);
    this.onNext = this.onNext.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.textInput = React.createRef(); 
    this.state={
      user:[],
      data:{},
      mobileNumber:'',
      mail:'',
      _id:'',
      subscriptions:{},
      noOfDays:'',
      courseid:[],
      fullName:{},
      trials:{},
      user:"",
      user:{},
      course:[]
    } 
}
handleChange() {
     
  const value = this.textInput.current.value;
  this.setState(
{
  mail: value
}
  )
}
onNext=async()=>{
  Cookies.set('name',this.state.name);
 
  var emailid=this.state.mail
  
  Cookies.set('email', this.state.mail);
  
  console.log( emailid)
 
  
  axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getUserFromEmail/"+emailid ,  {"withCredentials": true},{ headers: {
    "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": 
    "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
    }
  }).then(
  (posRes) => {
    console.log(posRes)
    this.setState({user: posRes.data.user})
    // alert('User found.  ')
    // Cookies.set('_id', posRes.data.user._id)
    
  },
  (errRes) => {
    console.log(errRes);
   alert('User not found. Please verify the details again.')
  }
  );
  var id = this.state.user._id   
axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getCourseSubscriptionDetails/"+id , 
 {"withCredentials": true},{ headers: {
  "Access-Control-Allow-Origin": "*",
   "Access-Control-Allow-Methods": "GET",
  "Access-Control-Allow-Headers": 
  "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
  }
}).then(
(posRes) => {
  console.log(posRes)
 // Cookies.set('chapterId', posRes.data.chapters[0].chapterId)
  this.setState({subscriptions: posRes.data.subscriptions})
},
(errRes) => {
  console.log(errRes);
}
);
axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getTrialDetails/"+emailid ,  {"withCredentials": true},{ headers: {
    "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": 
    "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
    }
  }).then(
  (posRes) => {
    console.log(posRes)
    this.setState({user: posRes.data.user})
    // alert('User found.  ')
    // Cookies.set('_id', posRes.data.user._id)
    
  },
  (errRes) => {
    console.log(errRes);
    // alert('User not found. Please verify the details again.')
  }
  );
  var emailid=this.state.mail
axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getTrialDetails/email/"+emailid ,  {"withCredentials": true},{ headers: {
    "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": 
    "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
    }
  }).then(
  (posRes) => {
    console.log(posRes)
    this.setState({trials: posRes.data.trials})
    // alert('User found.  ')
    // Cookies.set('_id', posRes.data.user._id)
    Cookies.set('courseid', posRes.data.trials[0].courseid)
    // alert('User found.  ')
    // Cookies.set('_id', posRes.data.user._id)
    
  },
  (errRes) => {
    console.log(errRes);
    // alert('User not found. Please verify the details again.')
  }
  );
 return
 
 };
onsubmit=async()=>{
  // Cookies.remove('_id')
  var mobileNumb=this.state.mobileNumber;
 
  Cookies.set('mobileNumber', mobileNumb);
  
  // Cookies.set('_id', data);
  console.log(mobileNumb)

  var data = {
    mobileNumber: mobileNumb
  }
  
  axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getUserFromMobileNumber/"+mobileNumb ,  {"withCredentials": true},{ headers: {
    "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": 
    "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
    }
  }).then(
  (posRes) => {
    console.log(posRes)
    this.setState({user: posRes.data.user})
    // alert('User found.')
   Cookies.set('_id', posRes.data.user._id)
    Cookies.set('email', posRes.data.user.email)
    //Cookies.set('courseid', posRes.data.user.trial[0].courseid)
    
  },
  (errRes) => {
    console.log(errRes);
    alert('User not found. Please verify the details again.')
  }
  );
  var id = this.state.user._id              //Cookies.get('_id')
axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getCourseSubscriptionDetails/"+id , 
 {"withCredentials": true},{ headers: {
  "Access-Control-Allow-Origin": "*",
   "Access-Control-Allow-Methods": "GET",
  "Access-Control-Allow-Headers": 
  "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
  }
}).then(
(posRes) => {
  console.log(posRes)
 // Cookies.set('chapterId', posRes.data.chapters[0].chapterId)
  this.setState({subscriptions: posRes.data.subscriptions, course: posRes.data.subscriptions.course})
},
(errRes) => {
  console.log(errRes);
}
);
var mobileNumb=this.state.mobileNumber;
axios.get("https://studypodapi.askiitians.com/admin/dashboard/user/getTrialDetails/"+mobileNumb ,  {"withCredentials": true},{ headers: {
    "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": 
    "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
    }
  }).then(
  (posRes) => {
    console.log(posRes)
    this.setState({trials: posRes.data.trials})
    // alert('User found.  ')
    // Cookies.set('_id', posRes.data.user._id)
    Cookies.set('courseid', posRes.data.trials[0].courseid)
    // alert('User found.  ')
    // Cookies.set('_id', posRes.data.user._id)
    
  },
  (errRes) => {
    console.log(errRes);
     alert('User not found. Please verify the details again.')
  }
  );
  
 return
 
 };

  onUpdate=(el)=>{
    
          alert('Do you really want to make changes?')
          var   days=this.state.noOfDays;
          Cookies.set('days',days)
           console.log(days)
            var data = {
       
                userId:  Cookies.get('_id'),
                courseId:  el.courseid ,                  
                noOfDays: days
         
            }
            console.log(days);
            axios({
                method: 'PUT',
                url: 'https://studypodapi.askiitians.com/admin/dashboard/user/updateFreeTrial',
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
              
        }
            )
            .then(
                async  (posRes) => {
              console.log(posRes)
              
              //this.setState({classes: posRes.data.classes})
              // alert('updated successfully')
            //  Cookies.set('isUserExist', true);
            var mobileNumb=this.state.mobileNumber;
            this.onsubmit()
            // var emailid=this.state.mail
            // this.onNext()
            },
            async  (errRes) => {
              console.log(errRes.response.data);
            
        
           
            }
            );
           
        }
        onUpdatemail=(el)=>{
    
          alert('Do you really want to make changes?')
          var   days=this.state.noOfDays;
          Cookies.set('days',days)
           console.log(days)
            var data = {
       
                userId:  Cookies.get('_id'),
                courseId:  el.courseid ,                  
                noOfDays: days
         
            }
            console.log(days);
            axios({
                method: 'PUT',
                url: 'https://studypodapi.askiitians.com/admin/dashboard/user/updateFreeTrial',
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
              
        }
            )
            .then(
                async  (posRes) => {
              console.log(posRes)
              
              //this.setState({classes: posRes.data.classes})
              // alert('updated successfully')
            //  Cookies.set('isUserExist', true);
             var mobileNumb=this.state.mobileNumber;
            //  this.onsubmit()
           // var emailid=this.state.mail
            //this.onNext()
            },
            async  (errRes) => {
              console.log(errRes.response.data);
            
        
           
            }
            );
           
        }
    render() {
      const customDomains = ['askiitians.com','gmail.com', 'yahoo.com']
        return (
            <div className='loginbackground'>
                <Row className='userpageheading'><p className='userpageheadingtext'>Filter By</p></Row>
              <Row>
             <Col className='Emailheadingclass'><p className='Emailheadingclasstext'>E-mail</p></Col>
             <Col className='Mobileheadingclass'><p className='Emailheadingclasstext'>Mobile</p></Col>
                
 <Col className='useremailclass'>
 <div className="form-group">
        
        {/* <Email 
        className="form-control" 
        placeholder="Enter email" 
        domains={customDomains}
        value={this.state.mail}
        onChange={mail => this.setState({ mail })}
        /> */}
         <InputGroup className="mb-3">
     
     <FormControl
          placeholder="Type User's Mail here..."
          aria-label="Username"
          aria-describedby="basic-addon1"
          ref={this.textInput} 
          type="text" 
          onChange={() => this.handleChange()}
  />
      </InputGroup>
      </div>
      </Col>      
      <Link onClick={this.onNext} >
                <Row className='searchbuttonuseremail'>
                  <p className='searchbuttonusertext'>Search</p>
                </Row>
                </Link>


                <Col className='usermobileclass'>
                <PhoneInput
  country={'in'}
  placeholder={"Phone Number"}
   value={this.state.mobileNumber}
   onChange={mobileNumber => this.setState({ mobileNumber })}
 
  />
                </Col>
                </Row>
                <Link onClick={this.onsubmit} >
                <Row className='searchbuttonuser'>
                  <p className='searchbuttonusertext'>Search</p>
                </Row>
                </Link>
               <div className='blockforusers'>
                
                   </div>
                   <div>
                   <Row className='tabsclasses' >
        <Tabs>
    <TabList>
     
      <Tab>TRIALS</Tab>
      <Tab>SUBSCRIPTIONS</Tab>
         <Tab>USER INFO</Tab>   
      {/* <Tab>TEST TRIAL</Tab> 
      <Tab>TEST SERIES SUBSCRIPTIONS</Tab>  */}
    </TabList>
    <TabPanel>
    {Object.keys(this.state.trials).length == 0?<p>No Record Found</p>:<div>
                {this.state.trials.map(
   (el,i)=>(
    
  
<div className='userinformationdiv'>
<Row>
<Col className='startheadingblocksss'>
<p className='facultyheadingblocktext'>Start Time: {moment.utc(moment(el.startTime)).format("MMMM Do, YYYY")}</p>
</Col>

<Col className='endheadingblocksss'>
<p className='facultyheadingblocktext'>End Time: {moment.utc(moment(el.endTime)).format("MMMM Do, YYYY")}</p>
</Col>
</Row>
<Row className='facultyheadingblockss'>
<p className='facultyheadingblocktexts'>Course Title: {el.courseTitle}</p> 

</Row>

 
<Row className='extenddaysblock'>
  <Col className='extendwords'>
  <p className='extendwordstext'>Extend the days of trial </p>
  </Col>
  <Col className='extenddaysblockpart'>
   
  <NumericInput min={1} className="form-control" onChange={noOfDays => this.setState({ noOfDays })}/>
</Col>
<Link onClick={()=>this.onUpdate(el)}> 
         <Col className='daysextendbutton'>
           <p className='daysextendbuttontext'>Update</p>
           </Col>   
</Link>
</Row>

 
  
</div>
 

                   )
                   )
                   }
                   </div>
                   } 
    </TabPanel>
    <TabPanel>
    {Object.keys(this.state.subscriptions).length == 0?<p>No Record Found</p>:<div>
                {this.state.subscriptions.map(
   (el,i)=>(
    
  
<div className='userinformationdiv'>
 
<Row>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Start Time </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {moment.utc(el.startDate).format('LLL')}</p>
  
</Col>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>End Time:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {moment.utc(el.endDate).format('LLL')}</p>
  
</Col>
</Row>
<Row>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>courseId: </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'>{el.courseId}</p>
  
</Col>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Course Title: </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {el.course.length!=0?el.course[0].courseTitle:"N/A"}</p>
  
</Col>
</Row>
</div>
 
                   )
                   )
                   }
                   </div>
                   } 

  {/* {Object.keys(this.state.data).length == 0?<p>No Record Found</p>:<div>
                {this.state.data.map(
   (el,i)=>(

    <>
    <div className='userinformationdiv'>
    
<Row>
<Col className='facultyheadingblockssss'>
<p className='facultyheadingblocktext'>Board: {el.board}</p>
  

</Col>
<Col className='facultyheadingblocks'>
<p className='facultyheadingblocktext'>Course Title: {el.courseTitle}</p>
  

</Col>
</Row>
    </div>
    </>
    )
    )
    }
    </div>
    }    */}

    </TabPanel>
  
   
    <TabPanel>
    
<div className='userinformationdiv'>
<Row>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Full Name:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {this.state.user.fullName}</p>
  
</Col>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Mobile Number:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {this.state.user.mobileNumber}</p>
  
</Col>
</Row>
<Row>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>E-mail:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {this.state.user.email}</p>
  
</Col>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Board:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {this.state.user.board}</p>
  
</Col>
</Row>
<Row>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Standard:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'> {this.state.user.standard}</p>
  
</Col>
<Col className='facultyheadingblockuser'>
<p className='facultyheadingblocktext'>Exam:  </p>
  
</Col>
<Col className='facultyheadingblockusers'>
<p className='facultyheadingblocktexts'>  {this.state.user.exam}</p>
  
</Col>
</Row>
</div>
 
    </TabPanel>
  
  </Tabs>
  </Row>
  </div>
            </div>
        )
    }
}

export default Userdetails
