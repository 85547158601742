import React, { Component } from 'react'
import Select from 'react-select';
import './Appbars.css';
import './Liveclassdashboard.css';
import Cookies from 'js-cookie';
import {Row, Col} from 'react-bootstrap';
import DatetimeRangePicker from 'react-datetime-range-picker';
import axios from 'axios';
import {BrowserRouter as Router, Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactPaginate from 'react-paginate';
import { InputGroup,FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types'
import DateTimePicker from 'react-datetime-picker';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Spinner from 'react-bootstrap/Spinner'
import FormLabel from '@material-ui/core/FormLabel';
// import 'devextreme/dist/css/dx.common.css';
// import 'devextreme/dist/css/dx.light.css';
import DatePicker from 'react-datetime';
// import DateBox from 'devextreme-react/date-box';
import ReactModal from 'react-modal';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
 
import 'react-accessible-accordion/dist/fancy-example.css';
 
import 'react-tabs/style/react-tabs.css';
// import { compareAsc, format } from 'date-fns'
 
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
const options = [
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  
];
const server_url = process.env.REACT_APP_SERVER_URL ;             
console.log(process.env.NODE_ENV)
export class Liveclassdashboard extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    perPage: PropTypes.number.isRequired,
  };
      constructor(props) {
        super(props);
        this.textInput = React.createRef(); 
        this.description=  [];
        this.now = new Date();
        this.state={
            selectedOption: '',
            selectedOptions: '',
            selectedOptionsss: '',
            faculties: [],
            courseCards:[],
            beginDateTime:'',
            beginTime:'',
            TimeBegin:'',
            endDateTime:'',
            instructor:'',
            mycards:'',
            classes:{},
            classDescription:'',
            upcoming:[],
            past:[],
            current:[],
            _id:[],
            url:'',
            classTitle:'',
            classTitlee:'',
            classCard:'', 
            facultyName:'',
            offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      instructorName:[],
      startTime:'',
      endTime:'' ,
      slice:[],
      slices:[],
      showModal: false,
      loading:false,
      standard:[],
      pageCount:'',
      pageCounts:'' ,
      cardImage:'',
      endClassTime:'',
      checking: false,
      checkingMini: false,
      beginDatesTime:'',
      classDescriptions:'',
      urll:'',
      classSize:false
        } 
        this.handlePageClick = this
        .handlePageClick
        .bind(this);   
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
  handleCreateStartTime = beginTime => {
    this.setState({beginTime});
     
    console.log(beginTime)
  }
  handleCreateendTime = endClassTime => {
    this.setState({endClassTime});
     
    console.log(endClassTime)
  }
    handleClassChange = selectedOptionsss => {
      this.setState({ selectedOptionsss });
      console.log(`Option selected:`, selectedOptionsss);
      Cookies.set('standard',  selectedOptionsss.value);
    };
    handleCardImage = cardImage => {
      this.setState({ cardImage });
      console.log(`Option selected:`, cardImage);
     // Cookies.set('standard',  cardImage.value);
    };
    handleOpenModal () {
      this.setState({ showModal: true });
    }
    handleLoading () {
      this.setState({ loading: true });
    }
    handleClassSize() {
      this.setState({ classSize: true });
    }
    handleCloseModal () {
      this.setState({ showModal: false });
    }
    
    handleChange= selectedOption => {
      this.setState({ selectedOption });
      console.log(`Option selected:`, selectedOption);
      Cookies.set('InstructorName',  selectedOption.value);
    
    };
    
    handleChangeinstructor = selectedOptions => {
      this.setState({ selectedOptions });
      console.log(`Option selected:`, selectedOptions);
      Cookies.set('facultyName',  selectedOptions.value);
    
    };

    handleChanges(e,i) {
      console.log(`updated:`,e.target.value);    
      console.log(i)
   const upcoming=[...this.state.upcoming]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   upcoming[i+x].classDescription=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    classDescription: value,upcoming
  }
 
      )
      console.log(value);
      Cookies.set('classDescription',value);
    };
    handleChangespast(e,i) {
      console.log(`updated:`,e.target.value);    
   const past=[...this.state.past]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   past[i+x].classDescription=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    classDescription: value,past
  }
 
      )
      console.log(value);
      Cookies.set('classDescription',value);
    };

    handleChangess(e,i) {
      console.log(`updated:`,e.target.value);    
   const upcoming=[...this.state.upcoming]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   upcoming[i+x].url=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    url: value,upcoming
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };
    handleChangesscurrent(e,i) {
      console.log(`updated:`,e.target.value);    
   const current=[...this.state.current]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   current[i+x].url=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    url: value,current
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };
    handleChangesspast(e,i) {
      console.log(`updated:`,e.target.value);    
   const past=[...this.state.past]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   past[i+x].url=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    url: value,past
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };
    handleTitle() {
     
      const value = this.textInput.current.value;
      console.log(value)
      this.setState(
  {
    classTitlee: value
  }
      )
      console.log(value) 
   }

    handleChangetitle(e,i) {
      console.log(`updated:`,e.target.value);    
      console.log(i)
   const upcoming=[...this.state.upcoming]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   upcoming[i+x].classTitle=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    classTitle: value,upcoming
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };


    handleChangetitlepast(e,i) {
      console.log(`updated:`,e.target.value);    
   const past=[...this.state.past]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   past[i+x].classTitle=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    classTitle: value,past
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };


    handleChangecard(e,i) {
      console.log(`updated:`,e.target.value);    
   const upcoming=[...this.state.upcoming]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   upcoming[i+x].classCard=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    classCard: value,upcoming
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };


    handleChangecardpast(e,i) {
      console.log(`updated:`,e.target.value);    
   const past=[...this.state.past]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   past[i+x].classCard=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    classCard: value,past
  }
 
      )
      console.log(value);
      Cookies.set('url',value);
    };


    handleChangesstart(e,i) {
      console.log(`update:`,new Date(e.target.value));    
   const upcoming=[...this.state.upcoming]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   upcoming[i+x].startTime=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    startTime: value,upcoming
  }
 
      )
      console.log(value);
      Cookies.set('startTime',value);
    };


    handleChangesstartpast(e,i) {
      console.log(`updated:`,e.target.value);    
   const past=[...this.state.past]
 
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   past[i+x].startTime=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    startTime: value,past
  }
 
      )
      console.log(value);
      Cookies.set('startTime',value);
    };

    
    
    handleChangesstendpast(e,i) {
      console.log(`updated:`,e.target.value);    
   const past=[...this.state.past]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   past[i+x].endTime=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    endTime: value,past
  }
 
      )
      console.log(value);
      Cookies.set('endTime',value);
    };



    handleChangesstend(e,i) {
      console.log(`updated:`,e.target.value);    
   const upcoming=[...this.state.upcoming]
   const x=this.state.currentPage*this.state.perPage
   console.log(i+x)
   upcoming[i+x].endTime=e.target.value
      const value = e.target.value;
      console.log(value);
      this.setState(
  {
    endTime: value,upcoming
  }
 
      )
      console.log(value);
      Cookies.set('endTime',value);
    };
    
    
    componentDidMount(){

     this.onCard()
    
          axios.get("https://studypodapi.askiitians.com/admin/dashboard/classes/getFaculties" , {"withCredentials": true},{ headers: {
            "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Methods": "GET",
            "Access-Control-Allow-Headers": 
            "Access-Control-Allow-Headers,Origin,strict-origin-when-cross-origin, X-Requested-With, Content-Type,Access-Control-Request-Headers, Accept,Access-Control-Request-Method, Authorization, Content-Type"
            }
          }).then(
          (posRes) => {
            console.log(posRes)
            // this.setState({faculties: posRes.data.faculties})
            Cookies.set('faculties', posRes.data.faculties)
            var i;
            var option=[]
            for (i = 0; i < posRes.data.faculties.length; i++) {
            
            option.push({"value":posRes.data.faculties[i],"label":posRes.data.faculties[i]})
            }
            
            this.setState({faculties: option})
          },
          (errRes) => {
            console.log(errRes);
          }
          );

        
        }
          
          
         onCard=()=>{
          axios.get("https://studypodapi.askiitians.com/admin/dashboard/classes/getCourseCards").then(
          (posRes) => {
            console.log(posRes)
            Cookies.set('courseCards', posRes.data.courseCards)
            var i;
            var optionn=[]
            for (i = 0; i < posRes.data.courseCards.length; i++) {
            
            optionn.push({"value":posRes.data.courseCards[i].courseCardURL,
            "label":posRes.data.courseCards[i].courseCardName})
            }
            
            this.setState({courseCards: optionn})
            console.log(optionn)
          },
          (errRes) => {
            console.log(errRes);
          }
          );
    
         }
      
         

          onsubmit=()=>{
            var startTim=this.state.beginDateTime;
         
            var endTim=this.state.endDateTime;
          
            var instruct=this.state.selectedOption;
            var standards= this.state.selectedOptionsss;
            if (instruct.value != null) {
              var instruc =instruct.value
          }
          else {
            instruc = '';
          }
          if (standards.value != null) {
            var classss =standards.value
        }
        else {
          classss = '';
        }
            // var instruc =instruct.value
             
            var data = {
              "beginDateTime":startTim,
              "endDateTime":endTim,
                "instructorName":instruc,
                "limit":10000,
                "standard" : classss
                // "pageNo":1
                // limit: this.props.perPage,
                // pageNo: this.state.offset
            }
            console.log(data)
            console.log(startTim,endTim,instruc);
            axios({
                method: 'POST',
                url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/filterClasses',
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
              
        }
            )
            .then(
                async  (posRes) => {
              console.log(posRes)
              this.setState({classes: posRes.data.classes,upcoming:posRes.data.classes.upcoming,past:posRes.data.classes.past,current:posRes.data.classes.current})
              console.log(this.state.classes.upcoming.length);
              const data =  posRes.data.classes.upcoming;
              const datas = posRes.data.classes.past;
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
              const slices = datas.slice(this.state.offset, this.state.offset + this.state.perPage)
              this.setState(
               {
                 slice: slice,
                 slices: slices
               }
              )
              
     
               this.setState({
                   pageCount: Math.ceil(data.length / this.state.perPage),
                   pageCounts: Math.ceil(datas.length / this.state.perPage),
                 
               })
          
          
              //  Cookies.set('isUserExist', true);
             // Cookies.set('classDescription', posRes.data.classes.upcoming[0].classDescription)
            // Cookies.set('url', posRes.data.classes.upcoming[0].url)
          
            // })
            this.handleLoading ();
            this.handleClassSize();
             },
            
            async  (errRes) => {
              alert('Please re-verify the input data.')
              
           
            }
            );
            // this.loadCommentsFromServer();
            // this.onsubmit();
        }
     
 
        onCreateClass=()=>{
          
          var startTimee=this.state.beginTime;
          console.log(startTimee)
          var title= this.state.classTitlee;
          var desc =this.state.classDescriptions;
          var link=this.state.urll;
          console.log(title)
          // Cookies.set('title',this.state.classTitlee);
           
     
          // var title=Cookies.get('title');
          var endTimee=this.state.endDateTime;
          console.log(endTimee)
          var instruct=this.state.selectedOption;
          var standards= this.state.selectedOptionsss;
          var cards= this.state.cardImage;
          if (instruct.value != null) {
            var instruc =instruct.value
        }
        else {
          instruc = '';
        }
        if (standards.value != null) {
          var classss =standards.value
      }
      else {
        classss = '';
      }
      if (cards.value != null) {
        var cardspic =cards.value
    }
    else {
      cardspic = '';
    }
          var instruc =instruct.value
           
          var data = {
            "instructorName":instruc,
            "classDescription": desc,
            "classTitle": title,
            "classCard": cardspic,
            "startTime":moment(startTimee).toISOString(),
            "endTime": moment(endTimee).toISOString(),
            "url": link,
            "standard":classss,
            "isMainCourse":this.state.checking? true:false,
            "isMiniCourse": this.state.checking? false:true
          }
          console.log(data)
          // console.log(startTim,endTim,instruc);
          axios({
              method: 'POST',
              url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/createNewClass',
              data: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  "Access-Control-Allow-Origin": "*",
              }
            
      }
          )
          .then(
            async  (posRes) => {
          console.log(posRes)
          
          //this.setState({classes: posRes.data.classes})
          alert('Class Created successfully')
        //  Cookies.set('isUserExist', true);
     
        },
        async  (errRes) => {
          // console.log(errRes.response.data);
          alert('All field are required')
        }
        );
       
    }




         

        handlePageClick = (e) => {
          const selectedPage = e.selected;
          const offset = selectedPage * this.state.perPage;
  
          this.setState({
              currentPage: selectedPage,
              offset: offset
          }, () => {
              this.onsubmit()
          });
  
      };

     

    
        onNext=(el)=>{
       
          Cookies.set('classDescription',this.state.classDescription);
         
          alert('Do you really want to make changes?')

          var classDescript=Cookies.get('classDescription');
          
           console.log(classDescript)
             var data = { 
              "isActive": true,
              "allClassNotes": [],
              "_id": el._id,
              "classDescription":   classDescript,
              "classTitle": el.classTitle,             
              "ChapterName": el.ChapterName,
              "classCard": el.classCard,
               "instructorName":  el.instructorName,
              "startTime": el.startTime, //"2021-04-15T12:00:00.000Z",
              "endTime":el.endTime,  // "2021-05-07T13:00:00.000Z",
              "url": el.url,
              "addedOn": el.addedOn
}
            
            console.log(classDescript);
            axios({
                method: 'PUT',
                url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
              
        }
            )
            .then(
                async  (posRes) => {
              console.log(posRes)
              //this.setState({classes: posRes.data.classes})
              alert('updated successfully')
            //  Cookies.set('isUserExist', true);
         
            },
            async  (errRes) => {
              console.log(errRes.response.data);
            
        
           
            }
            );
        }
        onNextFaculty=(el)=>{
         
            alert('Do you really want to make changes?')
  
            var faculty=Cookies.get('facultyName');
         
             console.log(faculty)
               var data = { 
                "isActive": true,
                "allClassNotes": [],
                "_id": el._id,
                "classDescription":el.classDescription,
                "classTitle": el.classTitle,             
                "ChapterName": el.ChapterName,
                "classCard": el.classCard,
                 "instructorName":faculty,
                "startTime": el.startTime, //"2021-04-15T12:00:00.000Z",
                "endTime":el.endTime,  // "2021-05-07T13:00:00.000Z",
                "url": el.url,
                "addedOn": el.addedOn
  }
              
              console.log(faculty);
              axios({
                  method: 'PUT',
                  url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                  data: JSON.stringify(data),
                  headers: {
                      'Content-Type': 'application/json;charset=UTF-8',
                      "Access-Control-Allow-Origin": "*",
                  }
                
          }
              )
              .then(
                  async  (posRes) => {
                console.log(posRes)
                //this.setState({classes: posRes.data.classes})
                alert('updated successfully')
                this.onsubmit()
                Cookies.remove('facultyName')
              //  Cookies.set('isUserExist', true);
           
              },
              async  (errRes) => {
                console.log(errRes.response.data);
              
          
             
              }
              );
          }
        onNexturl=(el)=>{
     
            Cookies.set('url',this.state.url);
          
            alert('Do you really want to make changes?')
  
       
            var urll=Cookies.get('url');
            
             console.log(urll)
               var data = { 
                "isActive": true,
                "allClassNotes": [],
                "_id": el._id,
                "classDescription":   el.classDescription,
                "classTitle": el.classTitle,             
                "ChapterName": el.ChapterName,
                "classCard": el.classCard,
                 "instructorName":  el.instructorName,
                "startTime": el.startTime, //"2021-04-15T12:00:00.000Z",
                "endTime":el.endTime,  // "2021-05-07T13:00:00.000Z",
                "url": urll,
                "addedOn": el.addedOn
  }
              
              console.log(urll);
              axios({
                  method: 'PUT',
                  url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                  data: JSON.stringify(data),
                  headers: {
                      'Content-Type': 'application/json;charset=UTF-8',
                      "Access-Control-Allow-Origin": "*",
                  }
                
          }
              )
              .then(
                  async  (posRes) => {
                console.log(posRes)
                //this.setState({classes: posRes.data.classes})
                alert('updated successfully')
              //  Cookies.set('isUserExist', true);
           
              },
              async  (errRes) => {
                console.log(errRes.response.data);
              
          
             
              }
              );
          }
          onNextcard=(el)=>{
         
              Cookies.set('classCard',this.state.classCard);
             // this.props.history.push('/Liveclassdashboard')
              alert('Do you really want to make changes?')
     
              var classCards=Cookies.get('classCard');
               console.log(classCards)
                 var data = { 
                  "isActive": true,
                  "allClassNotes": [],
                  "_id": el._id,
                  "classDescription":   el.classDescription,
                  "classTitle": el.classTitle,             
                  "ChapterName": el.ChapterName,
                  "classCard": classCards,
                   "instructorName":  el.instructorName,
                  "startTime": el.startTime, //"2021-04-15T12:00:00.000Z",
                  "endTime":el.endTime,  // "2021-05-07T13:00:00.000Z",
                  "url": el.url,
                  "addedOn": el.addedOn
    }
                
                console.log(classCards);
                axios({
                    method: 'PUT',
                    url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                    data: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                    }
                  
            }
                )
                .then(
                    async  (posRes) => {
                  console.log(posRes)
                  //this.setState({classes: posRes.data.classes})
                  alert('updated successfully')
                //  Cookies.set('isUserExist', true);
             
                },
                async  (errRes) => {
                  console.log(errRes.response.data);
                
            
               
                }
                );
            }
            onNexttitle=(el)=>{
           
                Cookies.set('classTitle',this.state.classTitle);
             
               // this.props.history.push('/Liveclassdashboard')
                alert('Do you really want to make changes?')
       
                var title=Cookies.get('classTitle');
                // var classCards=Cookies.get('classCard');
                 console.log(title)
                   var data = { 
                    "isActive": true,
                    "allClassNotes": [],
                    "_id": el._id,
                    "classDescription":   el.classDescription,
                    "classTitle":title,             
                    "ChapterName": el.ChapterName,
                    "classCard": el.classCard,
                     "instructorName":  el.instructorName,
                    "startTime": el.startTime, //"2021-04-15T12:00:00.000Z",
                    "endTime":el.endTime,  // "2021-05-07T13:00:00.000Z",
                    "url": el.url,
                    "addedOn": el.addedOn
      }
                  
                  console.log(title);
                  axios({
                      method: 'PUT',
                      url: 'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                      data: JSON.stringify(data),
                      headers: {
                          'Content-Type': 'application/json;charset=UTF-8',
                          "Access-Control-Allow-Origin": "*",
                      }
                    
              }
                  )
                  .then(
                      async  (posRes) => {
                    console.log(posRes)
                    //this.setState({classes: posRes.data.classes})
                    alert('updated successfully')
                  //  Cookies.set('isUserExist', true);
               
                  },
                  async  (errRes) => {
                    console.log(errRes.response.data);
                  
              
                 
                  }
                  );
              }
              onNextstart=(el)=>{
                
                  Cookies.set('startTime',this.state.startTime);
               
                  alert('Do you really want to make changes?')
        
                  // var classDescript=Cookies.get('classDescription');
                  // var urll=Cookies.get('url');
                  var start=Cookies.get('startTime');
                  // var classCards=Cookies.get('classCard');
                   console.log(start)
                     var data = { 
                      "isActive": true,
                      "allClassNotes": [],
                      "_id": el._id,
                      "classDescription":   el.classDescription,
                      "classTitle":el.classTitle,             
                      "ChapterName": el.ChapterName,
                      "classCard": el.classCard,
                       "instructorName":  el.instructorName,
                      "startTime": moment(start).toISOString(), //"2021-04-15T12:00:00.000Z",
                      "endTime":moment(el.endTime).toISOString(),  // "2021-05-07T13:00:00.000Z",
                      "url": el.url,
                      "addedOn": el.addedOn
        }
                    console.log(data)
                    console.log(start);
                    axios({
                        method: 'PUT',
                        url:  'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                        data: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            "Access-Control-Allow-Origin": "*",
                        }
                      
                }
                    )
                    .then(
                        async  (posRes) => {
                      console.log(posRes)
                      //this.setState({classes: posRes.data.classes})
                      alert('updated successfully')
                    //  Cookies.set('isUserExist', true);
                 
                    },
                    async  (errRes) => {
                      console.log(errRes.response.data);
                    
                
                   
                    }
                    );
                }
                onNextend=(el)=>{
                 
                    Cookies.set('endTime',this.state.endTime);
                   // Cookies.set('classCard',this.state.classCard);
                   // this.props.history.push('/Liveclassdashboard')
                    alert('Do you really want to make changes?')
          
                    // var classDescript=Cookies.get('classDescription');
                    // var urll=Cookies.get('url');
                    var end=Cookies.get('endTime');
                    // var classCards=Cookies.get('classCard');
                     console.log(end)
                       var data = { 
                        "isActive": true,
                        "allClassNotes": [],
                        "_id": el._id,
                        "classDescription":   el.classDescription,
                        "classTitle":el.classTitle,             
                        "ChapterName": el.ChapterName,
                        "classCard": el.classCard,
                         "instructorName":  el.instructorName,
                        "startTime": moment(el.startTime).toISOString(), //"2021-04-15T12:00:00.000Z",
                        "endTime":moment(end).toISOString(),  // "2021-05-07T13:00:00.000Z",
                        "url": el.url,
                        "addedOn": el.addedOn
          }
                      
                      console.log(end);
                      axios({
                          method: 'PUT',
                          url:  'https://studypodapi.askiitians.com/admin/dashboard/classes/updateClassDetails',
                          data: JSON.stringify(data),
                          headers: {
                              'Content-Type': 'application/json;charset=UTF-8',
                              "Access-Control-Allow-Origin": "*",
                          }
                        
                  }
                      )
                      .then(
                          async  (posRes) => {
                        console.log(posRes)
                        //this.setState({classes: posRes.data.classes})
                        alert('updated successfully')
                      //  Cookies.set('isUserExist', true);
                   
                      },
                      async  (errRes) => {
                        console.log(errRes.response.data);
                      
                  
                     
                      }
                      );
                  }
    render() {
      const { selectedOptions } = this.state;
      const { selectedOptionsss } = this.state;
      const { beginTime } = this.state;
      const { cardImage } = this.state;
      
   const inputProps={
        placeholder: "select",
        
      };
        const { selectedOption } = this.state;
      
    return (
        <div className='loginbackground'> 
        <Row  >
            <Col className='instructorselection'>
            <Row className='instructorheadingselector'>
                <p className='instructorheadingselectortext'>Choose Instructor</p></Row>
            <Row className='instructorselect'>
      <Select
        value={selectedOption}
         
        options={this.state.faculties}
        className='instructorselect'
         onChange={instructor => this.handleChange(instructor) }
      />
      </Row> 
      <Row className='instructorheadingselectors'>
                <p className='instructorheadingselectortext'>Choose Grade</p></Row>

                <Row className='instructorselects'>
                <Select
              className='instructorselects'   
        value={selectedOptionsss}
        onChange={this.handleClassChange}
        options={options}
      />
      </Row> 
      <Row className='orselectors'>
               <Col> <p className='instructorheadingselectortext'>OR</p></Col>
              
               <Col>
               
             <div>
     
        <Link onClick={this.handleOpenModal}>
               <Col className='createclass'> <p className='instructorheadingselectortextss'>CREATE CLASS</p></Col>
               </Link>
        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
        >
      
        <Row>
          <Col className='popupcontent'>
            <Row>
                <p className='instructorheadingselectortext'>Instructor Name</p> 
                </Row>
               
      <Select 
        value={selectedOption}
        className='popupcontentss'
        options={this.state.faculties}
         
         onChange={instructor => this.handleChange(instructor) }
      />
     
      </Col >
      <Col className='popupcontent'>
        <Row>
      <p className='instructorheadingselectortext'>Choose Class Card</p> 
      </Row>
         <Select
           value={cardImage}
           className='popupcontentss'
           options={this.state.courseCards}
            
            onChange={mycards=> this.handleCardImage(mycards)  }
         />
      </Col>
    </Row>
    <Row>
          <Col className='popupcontent'>
            <Row>
                <p className='instructorheadingselectortext'>Choose Standard</p> 
                </Row>
               
                <Select
              className='popupcontentss'  
        value={selectedOptionsss}
        onChange={this.handleClassChange}
        options={options}
      />
     
      </Col >
      <Col className='popupcontent'>
        <Row>
      <p className='instructorheadingselectortext'>Add URL</p> 
      </Row>
      <InputGroup className="mb-3">
     
     <FormControl
          placeholder="Type URL here..."
          aria-label="Username"
          className='popupcontentss'
          aria-describedby="basic-addon1"
          ref={this.textInput} 
          type="text" 
          value={this.state.urll}
          onChange={e => this.setState({ urll: e.target.value })}
          // onChange={() => this.handleChange()}
  />
      </InputGroup>
      </Col>
    </Row>
    <Row>
          <Col className='popupcontent'>
            <Row>
                <p className='instructorheadingselectortext'>Class Title</p> 
                </Row>
                <InputGroup className="mb-3">
     
     <FormControl
          placeholder="Type class Title here..."
          aria-label="Username"
          aria-describedby="basic-addon1"
          ref={this.textInput} 
          className='popupcontentss'
          type="text" 
           // onChange={() => this.handleTitle()}
          value={this.state.classTitlee}
          onChange={e => this.setState({ classTitlee: e.target.value })}
  
          
  />
      </InputGroup>
      </Col>
      <Col className='popupcontent'>
        <Row>
      <p className='instructorheadingselectortext'>Start Time</p> 
      </Row>
      <Row className='startdatecreateclass'>
       

       {/* <DateBox
                defaultValue={beginTime}
                type="datetime"
                pickerType="list"
                value={beginTime}
                onValueChanged={(e) => this.handleCreateStartTime(e.value)}
                onChange={(e) => this.handleCreateStartTime(e.value)}
                // onChange={beginTime => this.handleCreateStartTime(beginTime) }
               // onChange= {beginDatesTime =>this.setState({ beginDatesTime })}   
                // onEnterKey= {beginTime => this.handleCreateStartTime(beginTime) } 
                placeholder="12/31/2018, 2:52 PM"
                showClearButton={true}
                useMaskBehavior={true}
                adaptivityEnabled={true}
            /> */}
            <DatePicker
        inputProps={{
          style: { width: 426 },
          placeholder: "Select Start Time"
        }}
        value={beginTime}
        dateFormat="DD-MM-YYYY"
        timeFormat="hh:mm A"
        onChange={beginTime => moment(this.setState({ beginTime })).format('LLL')}   
      />
       </Row>
      </Col>
    </Row>
    <Row>
          <Col className='popupcontent' xs={6}>
            <Row>
                <p className='instructorheadingselectortext'>Class Description</p> 
                </Row>
                 
              
                <InputGroup className="mb-3">
     
     <FormControl
          placeholder="Type Description here..."
          aria-label="Username"
          className='popupcontentss'
          aria-describedby="basic-addon1"
          ref={this.textInput} 
          type="text" 
          //onChange={() => this.handleChange()}
          value={this.state.classDescriptions}
          onChange={e => this.setState({ classDescriptions: e.target.value })}
  />
      </InputGroup>
      
       
    
      </Col>
      <Col className='popupcontent' >
        <Row>
      <p className='instructorheadingselectortext'>End Time</p> 
      </Row >
      <Row className='startdatecreateclass'>
     
       <DatePicker
        inputProps={{
          style: { width: 426 },
          placeholder: "Select End Time"
        }}
        value={this.state.endDateTime}
        dateFormat="DD-MM-YYYY"
        timeFormat="hh:mm A"
        onChange={endDateTime => moment(this.setState({ endDateTime })).format('LLL')}   
      />
       </Row>
      </Col>
      
    </Row>
    <Row>
          <Col className='popupcontent'>
              <Row  >
              <p className='instructorheadingselectortext'>Choose Course Type</p> 
                </Row> 
          <Row className='popupcontents'>
          <RadioGroup aria-label="gender" name="gender1" row >
        <FormControlLabel 
        value="Full year Course" 
        control={<Radio />} 
        label="Full Year Course" 
        onChange={checking => this.setState({ checking : true }) }
        />
           
        <FormControlLabel 
        value="MiniCourse" 
        control={<Radio />} 
        label="Minicourse" />
         {/* onChange={checkingMini => this.setState({ checkingMini : true }) } */}
      </RadioGroup>
          </Row>
     
    
      </Col>
      <Col className='popupcontent'>
        <Row className='enddatecreateclass'>
       
      </Row>
      
      </Col>
      
    
    
     <Row>
     <Link onClick={this.onCreateClass}>
                <Col className='createclassbuttonpop' >
                    <p className='loginbuttonmenutexts'>Create Class</p>
                </Col>
                </Link>
     </Row>
    </Row>
          <button onClick={this.handleCloseModal} className='modalclose'>X</button>
        </ReactModal>
      </div>
               </Col>
               </Row>
      </Col>
      <Col className='dateandtimeclass'>
      <Row className='dateandtimeheadingselector'>
                <p className='instructorheadingselectortext'>Choose Start & End Date</p></Row>
      <DatetimeRangePicker
        // onChange={this.handler} 
        input='true'
        
       inputProps={{
          
        placeholder: "x"
      }}
        default="type"
       onEndDateChange={endDateTime => moment(this.setState({ endDateTime })).format()}
       onStartDateChange={beginDateTime => moment(this.setState({ beginDateTime })).format()}
        />
      </Col>
      <Link onClick={this.onsubmit}>
      <Col className='dashboardclasssearch'>
      <p className='dashboardclasssearchtext'>Search</p>
      </Col>
      </Link>
        </Row>
        <Row className='dividerclass'></Row>
        <Row className='tabsclass'>
        <Tabs>
    <TabList>
      <Tab>Past Classes {this.state.classSize?
      <text className='classsize'>({this.state.past.length})</text>:null } </Tab>
      <Tab>Live Classes {this.state.classSize?
      <text className='classsize'>({this.state.current.length})</text>:null }</Tab>
      <Tab>Upcoming Classes {this.state.classSize?
       <text className='classsize'>({this.state.upcoming.length})</text>:null }</Tab>
      
    </TabList>

    <TabPanel>

   <div>
                 { Object.keys(this.state.past).length == 0? this.state.loading?
                  (<p  className='messagetext'>No Past Classes Found for this search</p>):
                  (<p className='messagetext'>Please search your Class</p>)
                  :<div>
                  { this.state.slices.map((el,i) => <React.Fragment>
       <div className='classesinfo'>
<Accordion 
allowMultipleExpanded={true}
allowZeroExpanded={true}
>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton> 
      
      <Row>
      <Col className='facultyheadingblock'>
        <p className='facultyheadingblocktext'>{el.instructorName},(Class Title : {el.classTitle})</p>
      </Col>
      <Col className='startheadingblock'>
        <p className='facultyheadingblocktext'>Start Time: {moment(el.startTime).format('LLL')}</p>
      </Col>

      <Col className='endheadingblock'>
        <p className='facultyheadingblocktext'>End Time: {moment(el.endTime).format('LLL')}</p>
      </Col>
      </Row>
      <Row >
        <Col className='descriptiveheadingblocktop'>
        <RadioGroup aria-label="gender" name="gender1" row >
        <FormControlLabel value="Full Year Course" control={<Radio />} label="Full Year Course" 
          checked={el.isMainCourse?true:false}
         />
        <FormControlLabel value="Minicourse" control={<Radio />} label="Minicourse" 
       checked={el.isMiniCourse?true:false}
       />
        
      </RadioGroup>
      
   </Col >
   <Col className='descriptiveheadingblocktop'>
     <p className='facultyheadingblocktextss'>Standard : {el.standard}</p> </Col>
   </Row>
      <Row className='descriptiveheadingblocktop'>
     
        <p className='facultyheadingblocktexts'>{el.classDescription} </p>
      
      </Row>
      </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel> 
              <Row>
      <Col className='chapterheadingblock'>
        <p className='facultyheadingblocktext'>Instructor Name: </p>
      </Col>
      <Col className='descriptiveupdateheadingblocks'>
      <Select
        // value={selectedOption}
         
        options={this.state.faculties}
        className='instructorselect'
        //  onChange={instructor => this.handleChange(instructor) }
        onChange={(e) => this.handleChangeinstructor(e,i)}
      />
      
       
     </Col>
     <Link onClick={()=>this.onNextFaculty(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
</Row>
<Row>
      <Col className='chapterheadingblock'>
        <p className='facultyheadingblocktext'>Class Card: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.classCard}
      //  defaultValue="Hello World" 
       onChange={(e) => this.handleChangecardpast(e,i)}/>
      
       
     </Col>
     <Link onClick={()=>this.onNextcard(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
</Row>
<Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>Start Time: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      
         <TextField
        id="datetime-local"
        label="Edit End Time"
        type="datetime-local"
      
        value= {moment(el.startTime).format('LLL')}          
        
        onChange={(e) => this.handleChangesstartpast(e,i)}
        // className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
       
     </Col>
     <Link  onClick={()=>this.onNextstart(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
    
<Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>End Time: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      
       <TextField
        id="datetime-local"
        label="Edit End Time"
        type="datetime-local"
       
        value={moment(el.endTime).format('LLL')   }   
        placeholder="x"
        onChange={(e) => this.handleChangesstendpast(e,i)}
        // className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
        

     </Col>
     <Link  onClick={()=>this.onNextend(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
      
      <Row>
      <Col className='titleheadingblock'>
        <p className='facultyheadingblocktext'>Class Title: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.classTitle}
      //  defaultValue="Hello World" 
       onChange={(e) => this.handleChangetitlepast(e,i)}/> 
      
       
     </Col>
     <Link  onClick={()=>this.onNexttitle(el)}> 
      <Col className='descriptivebuttonheadingblock'>
        <p className='facultyheadingblocktext'>Update</p></Col>   </Link>
</Row>

      <Row>
      <Col className='descriptiveheadingblock'>
        <p className='facultyheadingblocktext'>Class Description: </p>
      </Col>
     
      <Col className='descriptiveupdateheadingblock'>
      
       <Col className='newdescription'>  
         <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.classDescription}
      defaultValue="Hello World" 
       onChange={(e) => this.handleChangespast(e,i)}/> 
         
       
      
      </Col> 
         {/* <Descriptionpopup/> */}
        
      </Col>
      <Link onClick={()=>this.onNext(el)}> 
       <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>

      </Row>
      <Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>url: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.url}
       defaultValue="Hello World" 
       onChange={(e) => this.handleChangesspast(e,i)}/> 
       
       
     </Col>
     <Link  onClick={()=>this.onNexturl(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
     
     
       </AccordionItemPanel>
          </AccordionItem>
      </Accordion>
       
     
               
       
           
            
      </div>
      </React.Fragment>)}
      <ReactPaginate
      previousLabel={"← Previous"}
      nextLabel={"Next →"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={this.state.pageCounts}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5 }
      onPageChange={this.handlePageClick}
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
      containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}/>
      </div>   } 
            
           
             </div>
      
  </TabPanel>
    <TabPanel>
   
    {Object.keys(this.state.current).length == 0?this.state.loading?
                  (<p  className='messagetext'>No Current Classes Found for this search</p>):
                  (<p  className='messagetext'>Please search your Class</p>):<div>
              {this.state.classes.current.map(
(el,i)=>(
  <>
    <div className='classesinfo'>

<Accordion 
allowMultipleExpanded={true}
allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
      <Row>
      <Col className='facultyheadingblock'>
        <p className='facultyheadingblocktext'>Instructor Name: {el.instructorName},(Class Title : {el.classTitle})</p>
      </Col>
      <Col className='startheadingblock'>
        <p className='facultyheadingblocktext'>Start Time: {moment(el.startTime).format('LLL')}</p>
      </Col>

      <Col className='endheadingblock'>
        <p className='facultyheadingblocktext'>End Time: {moment(el.endTime).format('LLL')}</p>
      </Col>
      </Row>
      <Row >
        <Col className='descriptiveheadingblocktop'>
      {el.isMainCourse?
     <p className='facultyheadingblocktexts'> Fullyearcourse </p> :
      <p className='facultyheadingblocktexts'> Minicourse </p>}
   </Col >
   <Col className='descriptiveheadingblocktop'><p className='facultyheadingblocktexts'>Standard : {el.standard}</p> </Col>
   </Row>
      <Row className='descriptiveheadingblocktop'>
     
        <p className='facultyheadingblocktexts'>{el.classDescription} </p>
      
      </Row>
      </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel> 
      <Row>
      <Col className='chapterheadingblock'>
        <p className='facultyheadingblocktext'>Class Card: {el.classCard}</p>
      </Col>
      <Col className='titleheadingblock'>
        <p className='facultyheadingblocktext'>Class Title: {el.classTitle}</p>
      </Col>
      
      
</Row>
      

      <Row>
      <Col className='descriptiveheadingblock'>
        <p className='facultyheadingblocktext'>Class Description: {el.classDescription}</p>
      </Col>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>URL: {el.url}</p>
      </Col>
      
      </Row>
      <Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>url: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.url}
       defaultValue="Hello World" 
       onChange={(e) => this.handleChangesscurrent(e,i)}/> 
       
       
     </Col>
     <Link  onClick={()=>this.onNexturl(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
     
      </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
      
 
            
      </div>
        <Row className='paginate'>
       
              
              </Row>
              </>
      )
      )
      }
      </div>
      }
              
  </TabPanel>
  <TabPanel>
   
     
     <div>
      
                 { 
                 
                 Object.keys(this.state.upcoming).length == 0?this.state.loading?
                 (<p className='messagetext'>No Upcoming Classes Found for this search</p>):
                 (<p  className='messagetext'>Please search your Class</p>):<div>
                 {
                   this.state.slice.map((el,i) => <React.Fragment>
                    
                    <div className='classesinfo'>
                   { this.state.loading ? 
<Accordion 
allowMultipleExpanded={true}
allowZeroExpanded={true}>
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton> 
      
      <Row>
      <Col className='facultyheadingblock'>
        <p className='facultyheadingblocktext'>{el.instructorName},(Class Title : {el.classTitle})</p>
      </Col>
      <Col className='startheadingblock'>
        <p className='facultyheadingblocktext'>Start Time:{moment(el.startTime).format('LLL')} </p> 
       
      </Col>

      <Col className='endheadingblock'>
        <p className='facultyheadingblocktext'>End Time:   {moment(el.endTime).format('LLL')}</p>
      </Col>
      </Row>
      <Row >
        <Col className='descriptiveheadingblocktop'>
        <RadioGroup aria-label="gender" name="gender1" row >
        <FormControlLabel value="Full Year Course" control={<Radio />} label="Full Year Course" 
          checked={el.isMainCourse?true:false}
         />
        <FormControlLabel value="Minicourse" control={<Radio />} label="Minicourse" 
       checked={el.isMiniCourse?true:false}
       />
        
      </RadioGroup>
   </Col >
   <Col className='descriptiveheadingblocktop'>
     <p className='facultyheadingblocktextss'>Standard : {el.standard}</p> </Col>
   </Row>
      <Row className='descriptiveheadingblocktop'>
     
        <p className='facultyheadingblocktexts'>{el.classDescription} </p>
      
      </Row>
      </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel> 
              <Row>
      <Col className='chapterheadingblock'>
        <p className='facultyheadingblocktext'>Instructor Name: </p>
      </Col>
      <Col className='descriptiveupdateheadingblocks'>
      <Select
        // value={selectedOption}
         
        options={this.state.faculties}
        className='instructorselect'
        //  onChange={instructor => this.handleChange(instructor) }
        onChange={(e) => this.handleChangeinstructor(e,i)}
      />
      
       
     </Col>
     <Link onClick={()=>this.onNextFaculty(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
</Row>
<Row>
      <Col className='chapterheadingblock'>
        <p className='facultyheadingblocktext'>Class Card: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.classCard}
   
       onChange={(e) => this.handleChangecard(e,i)}/>
      
       
     </Col>
     <Link onClick={()=>this.onNextcard(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
</Row>
<Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>Start Time: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      
        <TextField
        id="datetime-local"
        label="Edit Start Time"
        type="datetime-local"
        defaultValue="2017-05-24T10:30"
        value= {moment(el.startTime).format('LLL')}       
        placeholder="x"
        onChange={(e) => this.handleChangesstart(e,i)}
        
        InputLabelProps={{
          shrink: true,
        }}
      />
       
     </Col>
     <Link  onClick={()=>this.onNextstart(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
      <Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>End Time: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <form   noValidate>
      <TextField
        id="datetime-local"
        label="Edit End Time"
        type="datetime-local"
   
 defaultValue= {moment(el.endTime).format('LLL')}    
         initialValue=  {moment(el.endTime).format('LLL')}
         value=  {moment(el.endTime).format('LLL')   }     
          
         onChange=  {(e) =>this.handleChangesstend(e,i) }
       
        InputLabelProps={{
          shrink: true,
        }}
      />
       </form>
   
     </Col>
     <Link  onClick={()=>this.onNextend(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
     
      <Row>
      <Col className='titleheadingblock'>
        <p className='facultyheadingblocktext'>Class Title: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.classTitle}
   
       onChange={(e) => this.handleChangetitle(e,i)}/> 
      
       
     </Col>
     <Link  onClick={()=>this.onNexttitle(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
</Row>

      <Row>
      <Col className='descriptiveheadingblock'>
        <p className='facultyheadingblocktext'>Class Description: </p>
      </Col>
     
      <Col className='descriptiveupdateheadingblock'>
      
       <Col className='newdescription'>  
         <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.classDescription}
      defaultValue="Hello World" 
       onChange={(e) => this.handleChanges(e,i)}/> 
         
       
      </Col> 
      
        
      </Col>
      <Link onClick={()=>this.onNext(el)}> 
       <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>

      </Row>
      <Row>
      <Col className='urlheadingblock'>
        <p className='facultyheadingblocktext'>url: </p>
      </Col>
      <Col className='descriptiveupdateheadingblock'>
      <TextField 
       required id="standard-required" 
       label="Edit" 
       value={el.url}
       defaultValue="Hello World" 
       onChange={(e) => this.handleChangess(e,i)}/> 
       
       
     </Col>
     <Link  onClick={()=>this.onNexturl(el)}> 
      <Col className='descriptivebuttonheadingblock'><p className='facultyheadingblocktext'>Update</p></Col>   </Link>
      </Row>
     
     
       </AccordionItemPanel>
          </AccordionItem>
      </Accordion>
      :  <Spinner animation="border" />}
      </div>
      </React.Fragment>)
      
      } <ReactPaginate
      previousLabel={"← Previous"}
      nextLabel={"Next →"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={this.state.pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5 }
      onPageChange={this.handlePageClick}
      
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
      containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}/>
      </div>  
      } 
    
           
            </div>

      
              
  </TabPanel>
  
  </Tabs>
  </Row>
  
      </div>
    );
  }
}

export default Liveclassdashboard
