import React, { Component } from 'react'
import './Dashboard.css'
import './Liveclassdashboard.css';
import {BrowserRouter as Router, Link,Route, Switch} from 'react-router-dom';
export class Dashboard extends Component {
    render() {
        return (
            <Link to="/Liveclassdashboard"  className='loginbackground'>
            <div className='liveclasscard' >
                <h1 className='liveclasscardtext'>Live Classes</h1>
                
            </div>
            </Link>
        )
    }
}

export default Dashboard
