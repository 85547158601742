import React from 'react'
import {Row,Col} from 'react-bootstrap';
import './Appbars.css';
 
import {BrowserRouter as Router, Link} from 'react-router-dom';
import Cookies from 'js-cookie';
class Appbars extends React.Component {
    constructor(props){
        super(props) 
        this.state={
            naviagte:false
        };
    }
    onLogout=()=>{
        Cookies.remove("admin");
       this.setState({naviagte:true});
    }
   render(){
    return (
        <div>
            <Row className='appbarsblock'>
n           <Link to="/Dashboard">
            <Row className=' '>
                  <Col className='logoname'><p className='logonametext'>STUDYPOD</p></Col>
                  <Col className='logosubname'><p className='logosubnametext'>Operation Dashboard</p></Col>
            </Row>
            </Link>

                <Link onClick={this.onLogout}>
                <Col className='loginbuttonmenu' >
                    <p className='loginbuttonmenutext'>Logout</p>
                </Col>
                </Link>
                
            </Row>
        </div>
    )
}
}
export default Appbars
