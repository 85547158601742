import Cookies from 'js-cookie';
const admin = 'admin';

export const login = () => {
    Cookies.set(admin, 'TestLogin');
}

export const logout = () => {
   Cookies.remove(admin);
}

export const isLogin = () => {
    if (Cookies.get(admin)) {
        return true;
    }

    return false;
}