import React from 'react';
import {BrowserRouter as Router, Link,Route, Switch} from 'react-router-dom';
import Appbars from './components/Appbars';
import Liveclassdashboard from './components/Liveclassdashboard'
import Drawers from './components/Drawer';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Userdetails from './components/Userdetails';
import Login from './components/Login'
import Pagination from './components/Pagination.js';
function App() {
  return (
    <div className="App">
       <Router> 
      
    <Switch>
    {/* <Route path="/Login" exact>
            <Login/>
          </Route> */}
          <Route path="/" exact render={(routeProps) => <Login {...routeProps}/>}/>
          <Route path="/Pagination" exact>
            <Pagination/>
          </Route>
      <>
      
      <Appbars render={(routeProps) => <Appbars {...routeProps}/>}/>
      <Drawers/>
    {/* <Route path="/Dashboard" exact>
            <Dashboard/>
          </Route> */}
          <PrivateRoute component={Dashboard} path="/Dashboard" exact render={(routeProps) => <Dashboard {...routeProps}/>}/>
          <PrivateRoute component={Userdetails} path="/Userdetails" exact render={(routeProps) => <Userdetails {...routeProps}/>}/>
          <PrivateRoute component={Liveclassdashboard} path="/Liveclassdashboard" exact render={(routeProps) => <Liveclassdashboard {...routeProps}/>}/>
         
        
          {/* <Route path="/Liveclassdashboard" exact>
            <Liveclassdashboard/>
          </Route> */}
          </>


      </Switch>
      </Router> 
 
    </div>
  );
}

export default App;
