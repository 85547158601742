import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Row, Col,InputGroup,FormControl} from 'react-bootstrap';
import "./Login.css";
import {BrowserRouter as Router, Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
 class   Login extends React.Component {
  
  constructor(props) {
    super(props);
    this.textInput = React.createRef(); 
    this.state={
      user:'',
      password:''
    }
}
handlePassword() {
     
  const values = this.textInput.current.value;
  this.setState(
{
  password: values,
  user: values
}

  )
  console.log(values);
}
handleChange() {
     
  const valuess = this.textInput.current.value;
  this.setState(
{
  user: valuess
}
  )
  console.log(valuess);
}


onsubmit=()=>{
  Cookies.set('userName',this.state.user);
  Cookies.set('password',this.state.password);
  var user=this.state.user;
  var pwd=this.state.password;
  
  var data = {
    userName: "admin",
    password: pwd
  }
  console.log(user,pwd);
  axios({
      method: 'POST',
      url: 'https://studypodapi.askiitians.com/admin/dashboard/auth/login',
      data: JSON.stringify(data),
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    
}
  )
  .then(
      async  (posRes) => {
    console.log(posRes)
    this.setState({admin: posRes.data.admin })
  //  Cookies.set('isUserExist', true);
   Cookies.set('admin', posRes.data.admin._id);
  // Cookies.set('userID', posRes.data.userDetails._id);
  // alert('login successfull')
  this.props.history.push('/Dashboard')
  },
  async  (errRes) => {
    // console.log(errRes.response.data);
    alert('Invalid Credentials')

 
  }
  );
}
  
  render(){
  return (
    <div className='loginbackground Login'>
    <Row className='loginheading'><p className='loginheadingtext'>ADMIN PORTAL</p></Row>
     
    <Form >  
 
        <InputGroup className="mb-3">
     
     <FormControl
        //  placeholder="Type UserID here..."
        placeholder="ADMIN"
        value="ADMIN"
          aria-label="Username"
          aria-describedby="basic-addon1"
          ref={this.textInput} 
          type="text" 
          onChange={() => this.handlePassword()}
  /> 
   </InputGroup>
     
        <InputGroup className="mb-3">
         <FormControl
          placeholder="Type password here..."
          aria-label="Username"
          aria-describedby="basic-addon1"
          ref={this.textInput} 
          type="password" 
          onChange={() => this.handlePassword()}
  />
   </InputGroup>   

    <Link  onClick={this.onsubmit}>
        <Button block size="lg" type="submit" >
          Login
        </Button>
        </Link>
      </Form>
     
    </div>
     
  );
}
}
export default  Login